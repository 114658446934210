import { useState } from "react";
import { BookingFormData, ValidationError } from "./types";

// validation.ts
export const validateDrivingLicence = (licence: string): string | null => {
    // UK driving licence format:
    // - First 1-5 characters: Letters (from surname)
    // - Middle 6 characters: Numbers (including DOB)
    // - Last 5 characters: Random/check characters (letters and numbers)
    const licenceRegex = /^[A-Z9]{1,5}\d{6}[A-Z0-9]{5}$/;

    if (!licence) {
        return 'Driving licence number is required';
    }

    if (licence.length !== 16) {
        return 'Driving licence number must be exactly 16 characters long';
    }

    if (!licenceRegex.test(licence.toUpperCase())) {
        return 'Please enter a valid UK driving licence number';
    }

    return null;
};

export const validateTestReference = (reference: string): string | null => {
    if (!reference) {
        return 'Test reference number is required';
    }

    return null;
};

export const validateTheoryPass = (number: string): string | null => {
    if (!number) {
        return 'Theory pass number is required';
    }

    return null;
};

export const validateDateRange = (
    earliestDate: string,
    latestDate: string
): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!earliestDate) {
        errors.push({
            field: 'earliestDate',
            message: 'Earliest date is required'
        });
        return errors;
    }

    if (!latestDate) {
        errors.push({
            field: 'latestDate',
            message: 'Latest date is required'
        });
        return errors;
    }

    return errors;
};

export const useFormValidation = () => {
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = (data: BookingFormData): boolean => {
        const newErrors: ValidationError[] = [];

        // Validate licence number
        const licenceError = validateDrivingLicence(data.licenceNumber);
        if (licenceError) {
            newErrors.push({ field: 'licenceNumber', message: licenceError });
        }

        // Validate reference numbers based on type
        if (data.referenceType === 'testRef') {
            const refError = validateTestReference(data.testReferenceNumber);
            if (refError) {
                newErrors.push({ field: 'testReferenceNumber', message: refError });
            }
        } else {
            const theoryError = validateTheoryPass(data.theoryPassNumber);
            if (theoryError) {
                newErrors.push({ field: 'theoryPassNumber', message: theoryError });
            }
        }

        // Validate test center
        if (!data.testCenterId) {
            newErrors.push({
                field: 'testCenterId',
                message: 'Please select a test centre'
            });
        }

        // Validate dates
        const dateErrors = validateDateRange(data.earliestDate, data.latestDate);
        newErrors.push(...dateErrors);

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const validateField = (field: keyof BookingFormData, value: string): string | null => {
        switch (field) {
            case 'licenceNumber':
                return validateDrivingLicence(value);
            case 'testReferenceNumber':
                return validateTestReference(value);
            case 'theoryPassNumber':
                return validateTheoryPass(value);
            case 'testCenterId':
                return value ? null : 'Please select a test centre';
            default:
                return null;
        }
    };

    const getFieldError = (field: string): string | undefined => {
        return errors.find(error => error.field === field)?.message;
    };

    return {
        validateForm,
        validateField,
        getFieldError,
        errors,
        isSubmitting,
        setIsSubmitting,
    };
};