import React from 'react';

const HeroContent = () => {
    return (
        <div className="flex flex-col justify-center space-y-6">
            <div className="bg-yellow-50 dark:bg-yellow-900/30 text-yellow-800 dark:text-yellow-100 px-4 py-2 rounded-lg text-sm font-medium inline-flex items-center self-start">
                <span className="mr-2">⚡</span>
                Average wait time in UK: 6+ months
            </div>

            <h1 className="text-5xl font-bold">Skip 6-Month Driving Test Queues</h1>

            <p className="text-xl">
                While others wait months, our smart booking system finds and secures earlier test dates 24/7.
                Get tested sooner, save on extra lessons.
            </p>

            <div className="bg-white/50 dark:bg-gray-800/50 p-4 rounded-lg border border-gray-200 dark:border-gray-700">
                <div className="font-semibold mb-2">Why learners choose us:</div>
                <div className="space-y-3">
                    <div className="flex items-center gap-2">
                        <span className="text-green-600 dark:text-green-400 text-2xl">✓</span>
                        <span>Average 3-4 months saved on wait time</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="text-green-600 dark:text-green-400 text-2xl">✓</span>
                        <span>24/7 automated booking - never miss a cancellation</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="text-green-600 dark:text-green-400 text-2xl">✓</span>
                        <span>Save £300+ on extra lessons while waiting</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="text-green-600 dark:text-green-400 text-2xl">✓</span>
                        <span>From £60 - Money back if no earlier date found</span>
                    </div>
                </div>
            </div>

            {/* <div className="flex items-center gap-4 text-sm text-gray-600 dark:text-gray-400">
                <div className="flex items-center">
                    <span className="mr-1">⭐</span> 4.8/5 on Trustpilot
                </div>
            </div> */}
        </div>
    );
};

export default HeroContent;