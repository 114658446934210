import React from 'react';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="relative bg-base-100 p-6 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                <button
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={onClose}
                >
                    ✕
                </button>
                {/* <h1 className="text-4xl font-bold mb-6">{title}</h1> */}
                {children}
            </div>
        </div>
    );
}

export const usePolicyModals = () => {
    const [activeModal, setActiveModal] = React.useState<'terms' | null>(null);

    return {
        activeModal,
        openModal: setActiveModal,
        closeModal: () => setActiveModal(null)
    };
};