interface StepProps {
    number: string;
    title: string;
    text: string;
}

export const Step: React.FC<StepProps> = ({ number, title, text }) => (
    <div className="text-center p-4 bg-white/50 dark:bg-gray-800/50 rounded-lg border border-gray-200 dark:border-gray-700">
        <div className="w-8 h-8 bg-primary/10 text-primary rounded-full flex items-center justify-center mx-auto mb-2">
            {number}
        </div>
        <h3 className="font-semibold mb-1">{title}</h3>
        <p className="text-sm text-gray-600 dark:text-gray-400">{text}</p>
    </div>
);

export const HowItWorks = () => (
    <div className="mb-8">
        <h2 className="text-2xl font-bold mb-6 text-center">How It Works</h2>
        <div className="grid md:grid-cols-4 gap-4">
            <Step
                number="1"
                title="Have Existing Booking"
                text="You must already have a test booked with DVSA (any date/location)"
            />
            <Step
                number="2"
                title="Select Preferences"
                text="Choose your ideal test centre and date range"
            />
            <Step
                number="3"
                title="Quick Payment"
                text="One-time £60 fee to start monitoring"
            />
            <Step
                number="4"
                title="We Handle The Rest"
                text="When we find an appointment, we'll book it and notify you instantly"
            />
        </div>
    </div>
);