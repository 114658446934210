interface ComparisonItemProps {
    title: string;
    items: string[];
    highlighted?: boolean;
}

export const ComparisonItem: React.FC<ComparisonItemProps> = ({ title, items, highlighted }) => (
    <div className={`p-4 ${highlighted ? 'bg-primary/5 dark:bg-primary/10' : ''}`}>
        <h3 className="font-semibold mb-3">{title}</h3>
        <ul className="space-y-2">
            {items.map((item, index) => (
                <li key={index} className="flex items-center gap-2">
                    <span className={highlighted ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}>
                        {highlighted ? '✓' : '×'}
                    </span>
                    {item}
                </li>
            ))}
        </ul>
    </div>
);

export const ComparisonTable = () => (
    <div className="mb-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Why Choose Us</h2>
        <div className="grid md:grid-cols-2 gap-4 border rounded-lg overflow-hidden">
            <ComparisonItem
                title="Manual Booking"
                items={[
                    "Hours refreshing DVSA site",
                    "Often miss cancellations",
                    "6+ months wait"
                ]}
                highlighted={false}
            />
            <ComparisonItem
                title="Our Service"
                items={[
                    "24/7 automated monitoring",
                    "Automated booking",
                    "Average 1 month wait"
                ]}
                highlighted={true}
            />
        </div>
    </div>
);